<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-1banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">采购协同</div>
            <div class="head-explain-content">管理透明，高效执行</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
                <div class="purchase-content">形式多样不规范、重复动作耗工时</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">发运不精准</div>
                <div class="purchase-content">生产计划、安全库存、供应商供货能力信息不透明，采购仅凭经验拆分采购订单，无法真正实现按需送货</div>
                <div class="purchase-title">流程不透明</div>
                <div class="purchase-content">各环节业务流转需要采购参与和推动，“有问题、找采购”式工作模式，采购负担繁重;<br/>
                无信息共享平台，线下多媒介的沟通方式，供应商、采购、仓储、财务协同难;</div>
                <div class="purchase-title">绩效管控难</div>
                <div class="purchase-content">供应商考核体系不完善，供应质量问题追溯难; <br/>
                缺少供应商全采购周期的持续改进管理;</div>
                <div class="purchase-title">对账效率低</div>
                <div class="purchase-content">手工对账，整体时效性低、易出错;<br/>
                    流程繁琐不透明，采购需全程跟进;</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 67rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-1解决方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <img class="benefits-img" src="../assets/image/example/02-1方案收益.svg"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.5rem;
    height: 56.3rem;
}
.benefits-img{
    width: 97.7rem;
    height: 79.3rem;
}
</style>
